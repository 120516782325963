const images = [{
    src: "/image/gallery/Aihole-1.jpg",
    thumbnail: "/image/gallery/Aihole-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-2.jpg",
    thumbnail: "/image/gallery/Aihole-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-3.jpg",
    thumbnail: "/image/gallery/Aihole-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-4.jpg",
    thumbnail: "/image/gallery/Aihole-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-5.jpg",
    thumbnail: "/image/gallery/Aihole-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-6.jpg",
    thumbnail: "/image/gallery/Aihole-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-7.jpg",
    thumbnail: "/image/gallery/Aihole-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-8.jpg",
    thumbnail: "/image/gallery/Aihole-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},{
    src: "/image/gallery/Aihole-9.jpg",
    thumbnail: "/image/gallery/Aihole-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-10.jpg",
    thumbnail: "/image/gallery/Aihole-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-11.jpg",
    thumbnail: "/image/gallery/Aihole-11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Aihole-12.jpg",
    thumbnail: "/image/gallery/Aihole-12.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ", title: "ಐಹೊಳೆ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-1.jpg",
    thumbnail: "/image/gallery/badami-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-2.jpg",
    thumbnail: "/image/gallery/badami-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-3.jpg",
    thumbnail: "/image/gallery/badami-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-4.jpg",
    thumbnail: "/image/gallery/badami-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-5.jpg",
    thumbnail: "/image/gallery/badami-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-6.jpg",
    thumbnail: "/image/gallery/badami-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-7.jpg",
    thumbnail: "/image/gallery/badami-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-8.jpg",
    thumbnail: "/image/gallery/badami-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-9.jpg",
    thumbnail: "/image/gallery/badami-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-10.jpg",
    thumbnail: "/image/gallery/badami-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-11.jpg",
    thumbnail: "/image/gallery/badami-11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-12.jpg",
    thumbnail: "/image/gallery/badami-12.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-13.jpg",
    thumbnail: "/image/gallery/badami-13.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-14.jpg",
    thumbnail: "/image/gallery/badami-14.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-15.jpg",
    thumbnail: "/image/gallery/badami-15.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-16.jpg",
    thumbnail: "/image/gallery/badami-16.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-17.jpg",
    thumbnail: "/image/gallery/badami-17.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-18.jpg",
    thumbnail: "/image/gallery/badami-18.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-19.jpg",
    thumbnail: "/image/gallery/badami-19.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-20.jpg",
    thumbnail: "/image/gallery/badami-20.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-21.jpg",
    thumbnail: "/image/gallery/badami-21.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-22.jpg",
    thumbnail: "/image/gallery/badami-22.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-23.jpg",
    thumbnail: "/image/gallery/badami-23.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-24.jpg",
    thumbnail: "/image/gallery/badami-24.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-25.jpg",
    thumbnail: "/image/gallery/badami-26.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-27.jpg",
    thumbnail: "/image/gallery/badami-27.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-28.jpg",
    thumbnail: "/image/gallery/badami-28.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-29.jpg",
    thumbnail: "/image/gallery/badami-29.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-30.jpg",
    thumbnail: "/image/gallery/badami-30.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-31.jpg",
    thumbnail: "/image/gallery/badami-31.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-32.jpg",
    thumbnail: "/image/gallery/badami-32.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-33.jpg",
    thumbnail: "/image/gallery/badami-32.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/badami-34.jpg",
    thumbnail: "/image/gallery/badami-34.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ",
    tags: [{value: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ", title: "ಬಾದಾಮಿ, ಬಾಗಲಕೊಟೆ"}]
},
{
    src: "/image/gallery/Banavasi-1.jpg",
    thumbnail: "/image/gallery/Banavasi-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-2.jpg",
    thumbnail: "/image/gallery/Banavasi-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-3.jpg",
    thumbnail: "/image/gallery/Banavasi-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-4.jpg",
    thumbnail: "/image/gallery/Banavasi-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-5.jpg",
    thumbnail: "/image/gallery/Banavasi-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-6.jpg",
    thumbnail: "/image/gallery/Banavasi-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-7.jpg",
    thumbnail: "/image/gallery/Banavasi-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/Banavasi-8.jpg",
    thumbnail: "/image/gallery/Banavasi-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},{
    src: "/image/gallery/Banavasi-9.jpg",
    thumbnail: "/image/gallery/Banavasi-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಬನವಾಸಿ",
    tags: [{value: "ಬನವಾಸಿ", title: "ಬನವಾಸಿ"}]
},
{
    src: "/image/gallery/dambal-1.jpg",
    thumbnail: "/image/gallery/dambal-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
      caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}],
},
{
    src: "/image/gallery/dambal-2.jpg",
    thumbnail: "/image/gallery/dambal-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-3.jpg",
    thumbnail: "/image/gallery/dambal-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-3.jpg",
    thumbnail: "/image/gallery/dambal-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-4.jpg",
    thumbnail: "/image/gallery/dambal-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-5.jpg",
    thumbnail: "/image/gallery/dambal-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-6.jpg",
    thumbnail: "/image/gallery/dambal-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-7.jpg",
    thumbnail: "/image/gallery/dambal-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/dambal-8.jpg",
    thumbnail: "/image/gallery/dambal-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "ಡಂಬಳ",
    tags: [{value: "ಡಂಬಳ", title: "ಡಂಬಳ"}]
},
{
    src: "/image/gallery/Itagi-1.jpg",
    thumbnail: "/image/gallery/Itagi-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-2.jpg",
    thumbnail: "/image/gallery/Itagi-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-3.jpg",
    thumbnail: "/image/gallery/Itagi-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-4.jpg",
    thumbnail: "/image/gallery/Itagi-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-5.jpg",
    thumbnail: "/image/gallery/Itagi-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-6.jpg",
    thumbnail: "/image/gallery/Itagi-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-7.jpg",
    thumbnail: "/image/gallery/Itagi-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-8.jpg",
    thumbnail: "/image/gallery/Itagi-8.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-9.jpg",
    thumbnail: "/image/gallery/Itagi-9.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-10.jpg",
    thumbnail: "/image/gallery/Itagi-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-11.jpg",
    thumbnail: "/image/gallery/Itagi-11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-12.jpg",
    thumbnail: "/image/gallery/Itagi-12.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-13.jpg",
    thumbnail: "/image/gallery/Itagi-13.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Itagi-14.jpg",
    thumbnail: "/image/gallery/Itagi-14.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
   caption: "ಇಟಗಿ",
    tags: [{value: "ಇಟಗಿ", title: "ಇಟಗಿ"}]
},
{
    src: "/image/gallery/Koppala-1.jpg",
    thumbnail: "/image/gallery/Koppala-1.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-2.jpg",
    thumbnail: "/image/gallery/Koppala-2.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-3.jpg",
    thumbnail: "/image/gallery/Koppala-3.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-4.jpg",
    thumbnail: "/image/gallery/Koppala-4.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-5.jpg",
    thumbnail: "/image/gallery/Koppala-5.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-6.jpg",
    thumbnail: "/image/gallery/Koppala-6.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
},
{
    src: "/image/gallery/Koppala-7.jpg",
    thumbnail: "/image/gallery/Koppala-7.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
     caption: "ಕೊಪ್ಪಳ",
    tags: [{value: "ಕೊಪ್ಪಳ", title: "ಕೊಪ್ಪಳ"}]
}
];

export default images;