

export const LOG_IN = 'LOG_IN';

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';

export const GET_NEWS = 'GET_NEWS';

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';

export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID';



